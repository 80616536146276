import React from "react";

import Display from "../components/Display";

import ShowQuestion from "../submodule/src/App";

import { getRetrieval } from "../api/RetrievalsApi";
import {
  addQuestionRetData,
  addFeedback,
  addFeedback2,
} from "../api/QuestionsApi";
import { addMessage } from "../api/MessagesApi";

import logo from "../logoIMM.png";

import parameters from "../utils/parameters";
//import PopupBanner from "../components/Banner";

const QuestionWithRetrieval = () => {
  const otherReferences = false;

  return (
    <>
      <Display
        loading={false}
        error={null}
        centerTop={
          <ShowQuestion
            getRetrieval={getRetrieval}
            retrievalId={parameters.retrieval.retrievalId}
            addQuestionRetData={addQuestionRetData}
            addMessage={addMessage}
            logo={logo}
            otherRefs={otherReferences}
            addFeedback={addFeedback}
            addFeedback2={addFeedback2}
            newApi={false}
            colors={parameters.componentColors}
          />
        }
      />
      <div className="mx-10 md:mx-0 mb-20">
        <p className="text-justify text-xs text-gray-500 mx-auto w-full md:w-2/3">
          <b>AVISO</b>
          <br />
          Este servicio de chat proporciona respuestas de interés para el funcionariado de la Intendencia y los Municipios de Montevideo.
          <br />
          La institución no garantiza la exactitud, integridad y actualidad de las respuestas proporcionadas, ni se responsabiliza por eventuales errores y/o daños o perjuicios, directos o indirectos, derivados del uso de este servicio.
          <br />
          Las personas que hagan uso de la herramienta aceptan que la información proporcionada es de carácter meramente informativo. En caso de dudas, se recomienda realizar las consultas por los canales habituales.
          <br />
          Se exhorta no incluir información personal en la formulación de las preguntas, siendo responsabilidad del usuario proteger la privacidad de sus datos.
        </p>
      </div>
    </>
  );
};

export default QuestionWithRetrieval;
