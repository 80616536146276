const retrieval = {
  retrievalId: process.env.REACT_APP_RETRIEVAL,
};

const componentColors = {
  changeTopic: {

    backgroundColor: "#663399",
    textColor: "white",
    borderColor: "#663399",
    hoverColor: "#ab47bc",
  },
  matrixMode: {
    backgroundColor: "#663399",
    textColor: "white",
    borderColor: "#663399",
    hoverColor: "#ab47bc",
  },
  feedback: {
    backgroundColor: "#663399",
    borderColor: "#663399",
    hoverColor: "#ab47bc",
  },
  like: {
    backgroundColor: "#663399",
    borderColor: "#663399",
    hoverColor: "#ab47bc",
  },
  dislike: {
    backgroundColor: "#663399",
    borderColor: "#663399",
    hoverColor: "#ab47bc",
  },
  outline: {
    backgroundColor: "white",
    textColor: "#663399",
    borderColor: "#663399",
    hoverColor: "ab47bc",
  },

  arrowColor: "secondary",

  assistantTextColor: "text-purple-800",
  userTextColor: "text-purple-800",

};

const parameters = {
  componentColors,
  retrieval,
};

export default parameters;
