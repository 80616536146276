import api from '../utils/api';

export const addMessage = async (question, chat) => {
  const endpoint = `content/chats/${chat}/`;
  const resp = await api.post(endpoint, {'question': question});
  return resp;
};

const MessagesApi = {
  addMessage
};

export default MessagesApi;